import { call, put, takeLatest } from 'redux-saga/effects'

import {
  FAQ_REQUEST_FETCH_START,
  fetchUserActionInProgress,
  fetchUserActionDone,
  createFAQActionError,
} from './faqRequest.action'
import { fetchFaqList } from './faqRequest.service'
import { FaqType } from './faqRequest.domain'

export function* watchFetchFaqList() {
  yield takeLatest(FAQ_REQUEST_FETCH_START, callFetchFaqList)
}

function* callFetchFaqList() {
  try {
    yield put(fetchUserActionInProgress())

    const response: FaqType[] = yield call(fetchFaqList)
    yield put(fetchUserActionDone(response))
  } catch (error: unknown) {
    yield put(createFAQActionError(error as Error))
  }
}
