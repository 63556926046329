import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      common: {
        backToMainPage: 'Back to main page',
        createUser: 'Create a user',
        delete: 'delete',
        loggedAs: 'Logged in as',
        logout: 'Log out',
        select: {
          de: 'DE',
          en: 'EN',
        },
        imprint: 'Imprint',
        privacy: 'Privacy policy',
        close: 'Close',
        backToOverview: 'Back to overview',
        requiredFields: '*Required fields',
        back: 'Back',
        loadingData: 'loading data...',
        confirmResetPassword: 'Do You really want to reset {{userName}} user password?',
        confirmDeletePassword: 'Do You really want to delete {{userName}} user?',
        newPasswordCreated:
          'The password has been reset for the user: {{userName}}, the new password is: {{password}}',
        errorResetPassword: 'There was an issue resetting the password, please try again',
        searchUser: 'Search user',
        errorDownloadFile:
          'Unfortunately, an error occurred and the file could not be downloaded. Try again.',
        errorDelete:
          'Unfortunately, an error occurred and the item could not be removed. Try again.',
        generalError: 'Error',
      },
      adminUserForm: {
        createPassword: 'Password created',
        role: 'Role',
        fullName: 'Full name',
        phoneNumber: 'Phone number',
        addressLine1: 'Address (line 1)',
        addressLine2: 'Address (line 2)',
        postalCode: 'Postal code',
        city: 'City',
        country: 'Country',
        pleaseSelect: 'Please select',
        requiredField: 'This field is required',
        create: 'Create',
        new: 'New',
        edit: 'Edit',
        countries: {
          Germany: 'Germany',
          Austria: 'Austria',
          USA: 'USA',
          England: 'England',
          Canada: 'Canada',
        },
      },
      login: {
        topText: 'Register',
        title: 'Partner Area',
        pageDescribe:
          'Please enter your user data that you received from Burmester. Problems with your access data? Please contact us, we here to help.',
        username: 'Username',
        password: 'Password',
        errors: {
          wrongPassword: 'Login oder Passwort falsch.',
        },
        submit: 'Login',
        newAccountQuestion: 'No account yet?',
        contact: 'Please contact our',
        support: 'Support',
      },
      menu: {
        topText: 'Burmester',
        title: 'Partner Area',
        pageDescription:
          'Welcome to the Burmester Partner Area. Here you have access to all our service offerings.',
        options: {
          softwareUpdates: 'Software Updates',
          rmaService: 'RMA Service',
          mediaDatabase: 'Media database & manuals',
          warranty: 'Warranty extension',
          app: 'Burmester Apps',
          contact: 'Contact Burmester',
          exclusiveMaterial: 'Exclusive Material',
          experience: 'Burmester experience',
          bespoke: 'Bespoke',
          priceList: 'Individual price list',
          manuals: 'MANUALS',
          ownersClub: 'Ownersclub',
        },
        contactForm: {
          topText: 'SERVICE',
          title: 'Contact',
          pageDescription:
            'If you have any questions, our service staff will be happy to help. Please use the contact form for your request.',
          emailTitle: 'E-Mail Adress*',
          referenceTitle: 'Reference*',
          requestTitle: 'Request*',
          requiredInfo: '*Required fields',
          submitButton: 'SEND',
          error: 'Error!',
          errorMessage: 'Failed to submit the form. Please try again.',
          sendingData: 'Sending data...',
          formSentSuccessfully: 'The form has been successfully submitted',
        },
      },
      faq: {
        title: 'FAQ',
        info: 'INFORMATION',
      },
      repairRequestMenu: {
        topText: 'Partner Area',
        title: 'RMA Service',
        pageDescriptionLine1:
          'Check the status of your submitted service products or create a new service case.',
        pageDescriptionLine2:
          'It often turns out that supposed errors in the product only occur in combination with other devices, the cabling or under certain environmental conditions. Please speak to your customer to ensure that it is indeed a fault with the Burmester product. Please first inspect the device for the described issue. If you are unable to resolve the problem, please register the device here and then send it to us.',
        linkOption: 'CREATE A NEW RMA CASE',
        rmaTitle: 'MY RMA CASES',
        rma: {
          product: 'PRODUCT',
          serialNumber: 'SERIAL NUMBER',
          number: 'RMA NUMBER',
          lastChange: 'LAST CHANGED',
          status: 'STATUS',
          order: 'ORDER',
        },
        oldrma: {
          product: 'PRODUCT MODEL',
          number: 'SERIAL NUMBER',
          requestDatetime: 'REQUEST DATETIME',
          currentStatus: 'CURRENT STATUS',
          modificationDatetime: 'MODIFICATION DATETIME',
        },
      },
      repairRequest: {
        topText: 'Partner Area',
        title: 'New RMA case',
        pageDescription: 'Order a repair service from us',
        error: 'Error!',
        errorMessage: 'Failed to submit the form. Please try again.',
        form: {
          faqHint:
            'I have read the Technical Troubleshooting FAQ and taken the appropriate action. This did not fix the error described. To the FAQs',
          requiredField: 'This field is required',
          productSerial: {
            label: 'SERIAL NUMBER',
            link: 'Where can I find the serial number?',
            info: 'If there is no serial number available, please contact our Service and Customer Support at service@burmester.de or directly by phone at +49-30-78796896',
            error: 'Please enter your serial number',
          },
          warrantyTask: {
            text: 'SENT AS A PRESUMED WARRANTY CLAIM',
          },
          purchaseDate: 'DATE OF SALE',
          invoice: {
            label: 'SALES INVOICE',
            info: 'ALLOWED FILE FORMATS (MAX. 10 MB): PDF, JPG, PNG',
            text: 'If you suspect a warranty claim, please also submit the invoice.',
            errors: {
              maxFiles: 'Only 3 files are allowed',
              maxFileSize: 'One or more files are larger than the 10 MB limit',
              fileType: 'One or more files are not image files',
            },
          },
          versionSoftware: {
            label: 'VERSION OF THE SOFTWARE / FIRMWARE',
            info: 'Attention: We do not save any data in the form of a backup and assume no liability for the data on your device.',
          },
          AuthorizationToKindOfWork: {
            label:
              'I HEREBY AUTHORIZE THE FOLLOWING WORK TO BE PERFORMED ON THE REGISTERED DEVICE IN ADDITION TO THE SERVICE REQUEST ACCORDING TO THE FOLLOWING ERROR DESCRIPTION:',
            option1: 'SOFTWARE UPDATE',
            option2: 'FIRMWARE UPDATE',
          },
          productCategory: {
            label: 'PRODUCT CATEGORY',
            placeholder: 'Please select',
            error: 'Please select product category',
            items: {
              CD_PLAYER: 'CD-Player',
              DVD_PLAYER: 'DVD-Player',
              DA_CONVERTER: 'D/A converter',
              MUSICCENTER: 'musiccenter',
              NETWORK_PRODUCT: 'network player',
              FULL_AMPLIFIER: 'integrated amplifier',
              PRE_AMPLIFIER: 'preamplifier',
              AMPLIFIER: 'power amplifier',
              SPEAKER: 'loudspeaker',
              RECORD_PLAYER: 'turntable',
              TUNER: 'tuner',
              SURROUND_PROCESSOR: 'surround processor',
              ACTIVE_CROSSOVER: 'active crossover',
              PASSIV_CROSSOVER: 'passiv crossover',
              SYSTEM_909: '909 (System)',
              POWER_CONDITIONER: 'power conditioner',
              REMOTE_CONTROL: 'remote control',
              RACK: 'rack',
            },
          },
          productModel: {
            label: 'PRODUCT NAME OR MODEL',
            placeholder: 'Please select',
            error: 'Enter a product name or model',
          },
          newPackaging: {
            label: 'Do you need new packaging? (extra charge)',
            options: {
              yes: 'Yes',
              no: 'No',
            },
            error: 'Please choose an option',
          },
          productDescription: {
            label: 'ERROR DESCRIPTION',
            errors: {
              required: 'Enter a description of the error',
              minLength: 'The field has too few characters - please enter at least 20',
            },
          },
          accessories: {
            title: 'WHAT ACCESSORIES WILL YOU SEND?',
            Cable: 'Cable',
            Puck: 'Puck',
            Medium: 'Medium',
            Other: 'other',
            error: 'Enter the accessory name',
          },
          fileUpload: {
            label: 'FILE UPLOAD',
            errors: {
              maxFiles: 'Only 5 files are allowed',
              maxFileSize: 'The file is larger than the allowed limit of 25 MB',
            },
          },
          allowedFileFormats: 'ALLOWED FILE FORMATS (MAX. 25 MB): PDF, JPG, PNG, MP4, MOV',
          uploadPhotoMessage:
            'Please attach photos or videos that are as clear as possible and clearly show the error and the area surrounding the device.',
          shippingOption: {
            label: 'HOW TO SHIP THE PRODUCT',
            description1: 'The device is in the undamaged, complete original packaging.',
            description2:
              'The device is NOT in the undamaged, complete original packaging. Please send me new original packaging.',
          },
          additionalInfo: {
            label: 'REPAIR COSTS',
            text1:
              'Repairs are always carried out on the basis of the currently valid service price list. The products and their prices listed in the price list are accepted and the repair is hereby approved. Repairs to devices that are not listed in the price list will be determined with a cost estimate. For this cost estimate we charge a processing fee of €300.00 excluding VAT. The amount is due immediately if I request the device back without being repaired. Only after payment can the device be returned to me in accordance with the applicable regulations. If an order is placed, it will be offset against the later total invoice amount.',
            text2: 'Hereby I order the device to be picked up for a fee.',
            text3:
              'In the event of a warranty repair, Burmester will cover the logistics costs for the outward and return journey. If the test shows that the manufacturer is not at fault, I will bear the shipping costs.',
            error: 'Mandatory field',
          },
          importantNotes: {
            label: 'GENERAL IMPORTANT NOTES FROM THE MANUFACTURER',
            text1:
              '1. We only accept devices in their original packaging with an enclosed RMA number.',
            text2:
              '2. Please do not send in any accessories such as pucks, antennas, remote controls, etc. unless they are part of the error pattern. (See Technical FAQs).',
            text3: '3. We assume no liability for third-party goods sent in.',
            text4: '4. Please back up your data before sending in data carriers.',
          },
          confirmationText:
            'I have read the Troubleshooting Technical FAQ and taken the appropriate action. This did not fix the error described. To the FAQs',
          submitButton: 'Submit RMA case',
          error: 'Error!',
          validationMessage: 'Please complete all required fields before ordering.',
        },
        thankYou: {
          topText: 'NEW SERVICE-CASE',
          title: 'Thank you!',
          pageDescription:
            'Thank you for registering a service case. The order has been created and is now being processed by our service team.',
        },
      },
      repairRequestStatus: {
        topText: 'Partner Area',
        title: 'Meine Reparatur',
        repairTitle: 'Status Reparatur',
        status: 'Status',
        sending: 'SENDUNG VERFOLGEN',
        moreInformation: 'MEHR INFORMATIONEN',
      },
      burmesterApp: {
        topText: 'Partner Area',
        title: 'Burmester Apps',
        pageDescription:
          'Utilize our Burmester apps, both you and your customers, to gain a better understanding of our Burmester product offerings and have them readily available.',
        mc: 'BURMESTER MC',
        compose: 'BURMESTER COMPOSE',
        soundCheckMercedes: 'SOUNDCHECK APP MERCEDES-BENZ',
        soundCheckPorsche: 'SOUNDCHECK APP PORSCHE',
        menu: {
          downloadButton: 'DOWNLOAD',
        },
      },
      bespoke: {
        topText: 'Template',
        title: 'BESPOKE',
        LinkText: 'GO TO THE BESPOKE WEBSITE',
        pageDescriptionPart1:
          'Bespoke creates customized individual pieces. Together, we can create handmade unique\n' +
          'pieces according to personal customer wishes. We thoroughly check the feasibility of\n' +
          'every request to ensure that our high standards are met. After all, our quality is our top\n' +
          'priority and we attach particular importance to protecting biodiversity. The design of our\n' +
          'products, including the Burmester logo, remains unaffected.',
        pageDescriptionPart2:
          'In principle, each of our products is available as a Bespoke version.',
        pageDescriptionPart3:
          'To help you create a personalized Burmester product, we provide you with some initial\n' +
          'assistance here. Please consider our template as a kind of guideline for orientation. Send\n' +
          'the complete or partially completed template conveniently by e-mail to Christian Langner:',
        pageDescriptionPart4: 'He will guide you through the entire process.',
        bespokeProductTemplate: 'BESPOKE PRODUCT-TEMPLATE',
        pdfDownload: 'PDF DOWNLOAD',
        amplifierModel159: '159 POWER AMPLIFIER',
        amplifierModel216: '216 POWER AMPLIFIER',
        amplifierModel218: '218 POWER AMPLIFIER',
        amplifierModel232: '232 INTEGRATED AMPLIFIER',
        amplifierModel909: '909 POWER AMPLIFIER',
        loudspeakerModelB18: 'B18 LOUDSPEAKER',
        loudspeakerModelB28: 'B28 LOUDSPEAKER',
        loudspeakerModelB38: 'B38 LOUDSPEAKER',
        loudspeakerModelBC150: 'BC150 LOUDSPEAKER',
        loudspeakerModelBC350: 'BC350 LOUDSPEAKER',
        musicCenter111: '111 MUSICCENTER',
        pageDescriptionPart5: '... MORE TEMPLATES WILL FOLLOW',
        pageDescriptionPart6: 'OF COURSE, REQUESTS ARE ALSO POSSIBLE WITHOUT A TEMPLATE ...',
        contact: {
          title: 'DO YOU HAVE QUESTIONS ABOUT BESPOKE?',
          subtitle: 'Get in touch with our contact person.',
          personRole: 'Team leader inside sales',
          phone: 'Phone',
          email: 'Email',
        },
      },
      contact: {
        topText: 'Partner Area',
        title: 'Contact',
        pageDescription: 'If you have any questions, please contact our team.',
        email: 'E-mail',
        phoneNumber: 'Phone',
      },
      priceList: {
        existFileNameUploaded: 'File {{priceListName}} was already loaded',
        title: 'Current price list',
        topText: 'Partner Portal',
        dialogTitle: 'Upload price lists',
        B2B: 'B2B',
        B2C: 'B2C',
        SERVICE_PRICE_LIST: 'Service',
        b2bPriceList: 'B2B Price List',
        b2cPriceList: 'B2C Price List',
        servicePriceList: 'Service Price List',
        sendPriceList: 'send price list',
        error: 'Error!',
        errorFetchTypeMessage: 'Error fetching price list types',
        errorMessage: 'Failed to submit the form. Please try again.',
        errorLackOfCustomerRmaID:
          'The user needs to have Customer RmaId, complete in the admin panel Customer RmaId, and try again to upload a file',
        errorFetchPriceList: 'Something went wrong, we could not fetch the price lists',
        formSentSuccessfully: 'The form has been successfully submitted',
        noPriceList: 'No price lists to download!',
        pdfDownload: 'PDF DOWNLOAD',
        priceListType: 'PRICE LIST TYPE',
        fileName: 'FILE NAME',
      },
      profile: {
        topText: 'Partner Area',
        title: 'My profile',
        generalInformations: 'GENERAL INFORMATION',
        account: 'ACCOUNT',
        fields: {
          company: 'Company',
          contactPerson: 'Contact person',
          phoneNumber: 'Phone',
          emailAddress: 'E-Mail Address',
          website: 'Website',
          username: 'Username',
        },
      },
      dictionaryRepairRequestStatus: {
        dataSent: {
          display: {
            value: 'Daten übermittelt',
            subvalue: 'Datenüberprüfung',
            description:
              'Nach erfolgreicher Überprüfung der Daten erhalten Sie weitere Informationen zum Versand.',
          },
          file: {
            title: 'Benutzerrechnung',
            subtitle: '',
            button: 'Rechnung herunterladen',
          },
        },
        requestTaken: {
          display: {
            value: 'Auftrag angenommen',
            subvalue: 'Versand vorbereiten',
            description: 'Bitte bereiten Sie den Versand vor und schicken Sie die Ware ab.',
          },
          file: {
            title: 'Ihr Versandetikett',
            subtitle:
              'Bitte drucken Sie Ihr Versandetikett aus und bringen Sie es auf der Verpackung an. Sobald Ihr Paket abgeholt wurde, können Sie es hier per Trackingnummer verfolgen.',
            button: 'Etikett drucken',
          },
        },
        parcelReceived: {
          value: 'Ware angekommen',
          subvalue: 'Qualitätsprüfung',
          description: '',
        },
        costEstimation: {
          display: {
            value: 'KVA freigeben',
            description: '',
          },
          file: {
            title: 'Kostenvoranschlag - Reparatur',
            subtitle:
              'Bitte sehen Sie sich den folgenden Kostenvoranschlag an. Wenn Sie einverstanden sind, drücken Sie bitte auf "KVA freigeben" und Ihre Reparatur wird veranlasst.',
            button: 'KVA FREIGEBEN',
          },
        },
        repairFinished: {
          value: 'Reparatur fertigstellen',
          subvalue: 'Qualitätsprüfung',
          description: '',
        },
        receipt: {
          display: { value: 'Rechnung', description: '' },
          file: {
            title: 'Rechnung - Reparatur',
            subtitle:
              'Bitte begleichen Sie die Rechnung. Nachdem der Geldeingang verbucht wurde, wird Ihre Ware versandt.',
            button: 'RECHNUNG HERUNTERLADEN',
            status: 'offen',
          },
        },
        parcelSentBack: { value: 'Ware versandt', description: '' },
        default: {
          file: {
            title: 'Dokument',
            subtitle: '',
            button: 'Dokument herunterladen',
          },
        },
      },
    },
  },
  de: {
    translation: {
      common: {
        loggedAs: 'Eingeloggt als',
        logout: 'Abmelden',
        createUser: 'Create a user',
        select: {
          de: 'DE',
          en: 'EN',
        },
        imprint: 'Impressum',
        privacy: 'Datenschutz',
        close: 'Schließen',
        backToOverview: 'Zurück zur übersicht',
        requiredFields: '*Pflichtfelder',
        back: 'Züruck',
        confirmResetPassword:
          'Möchten Sie das Passwort des Benutzers {{userName}} wirklich zurücksetzen?',
        confirmDeletePassword: 'Möchten Sie den Benutzer {{userName}} wirklich löschen?',
        newPasswordCreated:
          'Das Passwort wurde für den Benutzer: {{userName}} zurückgesetzt, das neue Passwort lautet: {{password}}',
        errorResetPassword:
          'Beim Zurücksetzen des Passworts ist ein Problem aufgetreten. Bitte versuchen Sie es erneut',
        searchUser: 'Benutzer suchen',
        loadingData: 'Laden von Daten...',
        errorDownloadFile:
          'Leider ist ein Fehler aufgetreten und die Datei konnte nicht heruntergeladen werden. Versuchen Sie es erneut.',
        errorDelete:
          'Leider ist ein Fehler aufgetreten und der Artikel konnte nicht entfernt werden. Versuchen Sie es erneut.',
        generalError: 'Fehler',
      },
      adminUserForm: {
        createPassword: 'Passwort erstellt',
        role: 'Rolle',
        fullName: 'Ganze namen',
        phoneNumber: 'Telefonnummer',
        addressLine1: 'Adresse (Zeile 1)',
        addressLine2: 'Adresse (Zeile 2)',
        postalCode: 'Postleitzahl',
        city: 'Stadt',
        country: 'Land',
        pleaseSelect: 'Bitte auswählen',
        requiredField: 'Dieses Feld ist erforderlich',
        create: 'Erstellen',
        new: 'Neue',
        edit: 'Aktualisieren',
        countries: {
          Germany: 'Deutschland',
          Austria: 'Österreich',
          USA: 'USA',
          England: 'England',
          Canada: 'Kanada',
        },
      },
      login: {
        topText: 'Anmelden',
        title: 'Partner Portal',
        pageDescribe:
          'Bitte geben Sie Ihre Benutzerdaten ein, die Sie von Burmester erhalten haben. Sollten Sie noch über keine Zugangsdaten verfügen, nehmen Sie Kontakt zu uns auf.',
        username: 'Benutzername/ E-Mail Adresse',
        password: 'Passwort',
        errors: {
          wrongPassword: 'Login oder Passwort falsch.',
        },
        submit: 'Login',
        newAccountQuestion: 'Noch kein Konto?',
        contact: 'Kontaktieren Sie unseren',
        support: 'Support',
      },
      menu: {
        topText: 'Burmester',
        title: 'Partner Portal',
        pageDescription:
          'Herzlich Willkommen im Burmester Partner Portal. Hier haben Sie Zugriff auf all unsere Serviceseiten.',
        options: {
          softwareUpdates: 'Software Updates',
          rmaService: 'RMA Service',
          mediaDatabase: 'Medien Datenbank',
          warranty: 'Garantie Verlängerung',
          app: 'Burmester Apps',
          contact: 'Kontakt Burmester',
          exclusiveMaterial: 'Exklusives Material',
          experience: 'Burmester experience',
          bespoke: 'Bespoke',
          priceList: 'Individuelle Preisliste',
          manuals: 'MANUALS',
          ownersClub: 'Ownersclub',
        },
        contactForm: {
          topText: 'SERVICE',
          title: 'Kontakt',
          pageDescription:
            'Bei Fragen stehen Ihnen unsere Servicemitarbeiter gerne zu Verfügung. Nutzen Sie dafür bitte das Kontaktformular für Ihre Anfrage.',
          emailTitle: 'E-Mail Adresse*',
          referenceTitle: 'Betreff*',
          requestTitle: 'Ihr Anliegen*',
          requiredInfo: '*Pflichtfelder',
          submitButton: 'ABSENDEN',
          error: 'Fehler!',
          errorMessage: 'Das Formular konnte nicht gesendet werden. Bitte versuche es erneut.',
          sendingData: 'Daten senden...',
          formSentSuccessfully: 'Das Formular wurde erfolgreich gesendet.',
        },
      },
      faq: {
        title: 'FAQ',
        info: 'INFORMATIONEN',
      },
      repairRequestMenu: {
        topText: 'Partner Portal',
        title: 'RMA Service',
        pageDescriptionLine1:
          'Überprüfen Sie den Status Ihrer eingereichten Serviceprodukte oder legen Sie einen neuen Servicefall an.',
        pageDescriptionLine2:
          'Häufig stellt sich heraus, dass vermeintliche Fehler am Produkt nur in Kombination mit anderen Geräten, der Verkabelung oder unter bestimmten Umgebungsbedingungen auftreten. Bitte sprechen Sie mit Ihrem Kunden um sicherzustellen, dass es sich tatsächlich um einen Fehler am Burmester-Produkt handelt. Bitte prüfen Sie zunächst das Gerät hinsichtlich des beschriebenen Fehlers. Im Falle einer nicht möglichen Behebung des Fehlers, melden Sie das Gerät bitte hier an und senden Sie dieses anschließend an uns.',
        linkOption: 'NEUER SERVICEFALL',
        rmaTitle: 'MEINE RMA-ÜBERSICHT',
        rma: {
          product: 'PRODUKT',
          serialNumber: 'SERIENNUMMER',
          number: 'RMA NUMBER',
          lastChange: 'ZULETZT GEÄNDERT',
          status: 'STATUS',
          order: 'AUFTRAG',
        },
        oldrma: {
          product: 'PRODUKTBEZEICHNUNG',
          number: 'SERIENNUMMER',
          requestDatetime: 'ABGABEDATUM',
          currentStatus: 'STATUS',
          modificationDatetime: 'MODIF. DATUM',
        },
      },
      repairRequest: {
        topText: 'Partner Portal',
        title: 'Neuer Servicefall',
        error: 'Fehler!',
        errorMessage: 'Das Formular konnte nicht gesendet werden. Bitte versuche es erneut.',
        pageDescription: 'Reparatur in Auftrag geben',
        form: {
          faqHint:
            'Ich habe die Technischen FAQ zur Fehlerbehebung gelesen und die entsprechenden Maßnahmen durchgeführt. Der beschriebene Fehler ließ sich dadurch nicht beheben. Zu den FAQs',
          requiredField: 'Dieses Feld ist erforderlich',
          productSerial: {
            label: 'SERIENNUMMER',
            link: 'Wo finde ich die Seriennummer?',
            info: 'Wenn keine Seriennummer vorhanden ist, kontaktieren Sie bitte unseren Service und Customer Support unter <a href="mailto:service@burmester.de">service@burmester.de</a> oder telefonisch unter der +49-30-78796896',
            error: 'Bitte geben sie ihre Seriennummer ein',
          },
          warrantyTask: {
            text: 'EINSENDUNG ALS VERMUTETER GARANTIEANTRAG',
          },
          purchaseDate: 'DATUM DES VERKAUFS',
          invoice: {
            label: 'VERKAUFSRECHNUNG',
            info: 'ERLAUBTE DATEIFORMATE (MAX. 10 MB): PDF, JPG, PNG',
            text: 'Im vermuteten Garantiefall bitte die Rechnung mit einreichen.',
            errors: {
              maxFiles: 'Nur 3 Dateien sind erlaubt',
              maxFileSize:
                'Eine oder mehrere Dateien sind größer als die erlaubte Obergrenze von 10 MB',
              fileType: 'Eine oder mehrere Dateien sind keine Bilddateien',
            },
          },
          versionSoftware: {
            label: 'VERSION DER SOFTWARE / FIRMWARE',
            info: 'Achtung: Wir sichern keine Daten in Form eines Backups und übernehmen keine Haftung für die Daten auf ihrem Gerät.',
          },
          AuthorizationToKindOfWork: {
            label:
              'HIERMIT BEAUFTRAGE ICH, DIE FOLGENDEN ARBEITEN AM ANGEMELDETEN GERÄT, ZUSÄTZLICH ZUR SERVICEANFRAGE GEMÄSS DER NACHFOLGENDEN FEHLERBESCHREIBUNG, DURCHZUFÜHREN:',
            softwareEffort: 'SOFTWARE UPDATE',
            firmwareEffort: 'FIRMWARE UPDATE',
          },
          productCategory: {
            label: 'GERÄTEKATEGORIE',
            placeholder: 'Bitte auswählen',
            error: 'Bitte auswählen Gerätekategorie',
            items: {
              CD_PLAYER: 'CD-Player',
              DVD_PLAYER: 'DVD-Player',
              DA_CONVERTER: 'D/A Wandler',
              MUSICCENTER: 'Musiccenter',
              NETWORK_PRODUCT: 'Netzwerkspieler',
              FULL_AMPLIFIER: 'Vollverstärker',
              PRE_AMPLIFIER: 'Vorverstärker',
              AMPLIFIER: 'Endverstärker',
              SPEAKER: 'Lautsprecher',
              RECORD_PLAYER: 'Plattenspieler',
              TUNER: 'Tuner',
              SURROUND_PROCESSOR: 'Surround Prozessor',
              ACTIVE_CROSSOVER: 'Aktive Frequenzweiche',
              PASSIV_CROSSOVER: 'Passive Frequenzweiche',
              SYSTEM_909: '909 (System)',
              POWER_CONDITIONER: 'Stromfilter',
              REMOTE_CONTROL: 'Fernbedienung',
              RACK: 'Rack',
            },
          },
          productModel: {
            label: 'PRODUKTBEZEICHNUNG',
            placeholder: 'Bitte auswählen',
            error: 'Geben Sie einen Produktbezeichnung',
          },
          newPackaging: {
            label: 'Brauchen Sie eine neue Verpackung? (gegen Aufpreis)',
            options: {
              yes: 'Ja',
              no: 'Nein',
            },
            error: 'Bitte wählen Sie eine Option',
          },
          productDescription: {
            label: 'BESCHREIBUNG DES FEHLERS',
            errors: {
              required: 'Geben Sie einen Beschreibung des Fehlers',
              minLength: 'Das Feld hat zu wenig Zeichen - bitte geben Sie mindestens 20 ein',
            },
          },
          accessories: {
            title: 'WELCHES ZUBEHÖR SENDEN SIE MIT?',
            Cable: 'Kabel',
            Puck: 'Puck',
            Medium: 'Tonträger',
            Other: 'Anderes',
            error: 'Geben Sie den Zubehörnamen ein',
          },
          fileUpload: {
            label: 'DATEI UPLOAD',
            errors: {
              maxFiles: 'Nur 5 Dateien sind erlaubt',
              maxFileSize: 'Die Datei ist größer als die zulässige Grenze von 25 MB',
            },
          },
          allowedFileFormats: 'ERLAUBTE DATEIFORMATE (MAX. 25 MB): PDF, JPG, PNG, MP4, MOV',
          uploadPhotoMessage:
            'Bitte fügen Sie möglichst aussagekräftige Fotos oder Videos bei, auf denen der Fehler und auch das Umfeld des Gerätes gut zu erkennen ist.',
          shippingOption: {
            label: 'WIE VERSENDEN SIE DAS PRODUKT',
            description1:
              'Das Gerät befindet sich in der unbeschädigten, vollständigen Originalverpackung.',
            description2:
              'Das Gerät befindet sich NICHT in der unbeschädigten, vollständigen Originalverpackung. Bitte schicken Sie mir eine neue Originalverpackung zu.',
          },
          additionalInfo: {
            label: 'REPARATURKOSTEN',
            text1:
              'Eine Instandsetzung erfolgt stets auf Basis der aktuell gültigen Servicepreisliste. Die in der Preisliste aufgeführten Produkte und deren Preise werden akzeptiert und die Reparatur ist hiermit freigegeben. Reparaturen von Geräten, die nicht in der Preisliste aufgeführt sind, werden mit einem Kostenvoranschlag ermittelt. Für diesen Kostenvoranschlag berechnen wir eine Bearbeitungsgebühr in Höhe von 300,00 € exkl. MwSt. Der Betrag ist sofort fällig, wenn ich das Gerät unrepariert zurückfordere. Erst nach Zahlung kann das Gerät gemäß den geltenden Bestimmungen an mich zurückgesendet werden. Im Falle einer Beauftragung wird er mit dem späteren Rechnungsgesamtbetrag verrechnet.',
            text2: 'Hiermit beauftrage ich eine kostenpflichtige Abholung des Gerätes.',
            text3:
              'Im Falle einer Garantiereparatur übernimmt Burmester die Kosten für die Logistik für Hin- und Rückweg. Sollte die Prüfung ergeben, dass kein Verschulden des Herstellers vorliegt, trage ich die Kosten des Versandes.',
            error: 'Pflichtfeld',
          },
          importantNotes: {
            label: 'ALLGEMEINE WICHTIGE HINWEISE VOM HERSTELLER',
            text1: '1. Wir nehmen nur originalverpackte Geräte mit beiliegender RMA-Nummer an.',
            text2:
              '2. Bitte senden Sie kein Zubehör wie Pucks, Antennen, Fernbedienungen o.ä. ein, es sei denn, es gehört zum Fehlerbild. (Siehe Technische FAQs).',
            text3: '3. Für eingesendete Fremdware übernehmen wir keine Haftung.',
            text4: '4. Bitte sichern Sie Ihre Daten, bevor Sie Datenträger einsenden.',
          },
          confirmationText:
            'Ich habe die Technischen FAQ zur Fehlerbehebung gelesen und die entsprechenden Maßnahmen durchgeführt. Der beschriebene Fehler ließ sich dadurch nicht beheben. Zu den FAQs',
          submitButton: 'AUFTRAG ABSENDEN',
          error: 'Fehler!',
          validationMessage: 'Bitte füllen Sie vor der Bestellung alle Pflichtfelder aus.',
        },
        thankYou: {
          topText: 'NEUER SERVICE-FALL',
          title: 'Vielen Dank!',
          pageDescription:
            'Vielen Dank für die Anmeldung eines Service Falls. Der Auftrag wurde angelegt und wird nun von unserem Service-Team bearbeitet.',
        },
      },
      repairRequestStatus: {
        topText: 'Partner Portal',
        title: 'Meine Reparatur',
        repairTitle: 'Status Reparatur',
        status: 'Status',
        sending: 'SENDUNG VERFOLGEN',
        moreInformation: 'MEHR INFORMATIONEN',
      },
      burmesterApp: {
        topText: 'Partner Portal',
        title: 'Burmester Apps',
        pageDescription:
          'Nutzen Sie und Ihre Kundinnen und Kunden unsere Burmester Apps, um unsere Burmester Produktwelten noch besser kennenzulernen und diese stets zur Hand zu haben.',
        mc: 'BURMESTER MC',
        compose: 'BURMESTER COMPOSE',
        soundCheckMercedes: 'SOUNDCHECK APP MERCEDES-BENZ',
        soundCheckPorsche: 'SOUNDCHECK APP PORSCHE',
        menu: {
          downloadButton: 'HERUNTERLADEN',
        },
      },
      bespoke: {
        topText: 'Template',
        title: 'BESPOKE',
        LinkText: 'ZUR BESPOKE - WEBSITE',
        pageDescriptionPart1:
          'Mit Bespoke entstehen maßgeschneiderte Einzelstücke. Gemeinsam können wir\n' +
          'handgefertigte Unikate nach persönlichen Kundenwünschen umsetzen. Jede Anfrage wird\n' +
          'von uns gründlich auf Machbarkeit geprüft, um die Einhaltung unserer hohen Standards\n' +
          'sicherzustellen. Denn unsere Qualität hat höchste Priorität und wir legen besonderen\n' +
          'Wert auf den Schutz der Artenvielfalt. Die Gestaltung unserer Produkte, einschließlich des\n' +
          'Burmester-Logos, bleibt unberührt.',
        pageDescriptionPart2:
          'Grundsätzlich ist jedes unserer Produkte als Bespoke-Version erhältlich.',
        pageDescriptionPart3:
          'Zur Verwirklichung eines personalisierten Burmester-Produkts geben wir Ihnen hier eine\n' +
          'erste Hilfestellung an die Hand. Bitte verstehen Sie unser Template als eine Art Leitfaden,\n' +
          'der zur Orientierung dient. Übermitteln Sie das vollständige oder teilweise ausgefüllte\n' +
          'Template bequem via E-Mail an Christian Langner:',
        pageDescriptionPart4: 'Er wird Sie im gesamten Prozess begleiten.',
        bespokeProductTemplate: 'BESPOKE PRODUKT-TEMPLATE',
        pdfDownload: 'PDF DOWNLOAD',
        amplifierModel159: '159 ENDVERSTÄRKER',
        amplifierModel216: '216 ENDVERSTÄRKER',
        amplifierModel218: '218 ENDVERSTÄRKER',
        amplifierModel232: '232 VOLLVERSTÄRKER',
        amplifierModel909: '909 ENDVERSTÄRKER',
        loudspeakerModelB18: 'B18 LAUTSPRECHER',
        loudspeakerModelB28: 'B28 LAUTSPRECHER',
        loudspeakerModelB38: 'B38 LAUTSPRECHER',
        loudspeakerModelBC150: 'BC150 LAUTSPRECHER',
        loudspeakerModelBC350: 'BC350 LAUTSPRECHER',
        musicCenter111: '111 MUSICCENTER',
        pageDescriptionPart5: '... WEITERE TEMPLATES FOLGEN.',
        pageDescriptionPart6: 'ANFRAGEN SIND SELBSTVERSTÄNDLICH AUCH OHNE TEMPLATE MÖGLICH ...',
        contact: {
          title: 'HABEN SIE FRAGEN ZU BESPOKE?',
          subtitle: 'Kontaktieren Sie unseren Ansprechpartner.',
          personRole: 'Teamleiter Vertriebsinnendienst',
          phone: 'Tel',
          email: 'Email',
        },
      },
      contact: {
        topText: 'Partner Portal',
        title: 'Kontakt',
        pageDescription: 'Nehmen Sie gezielten Kontakt zu unseren Mitarbeitern auf.',
        email: 'E-mail',
        phoneNumber: 'Telefon',
      },
      priceList: {
        existFileNameUploaded: 'Die Datei {{priceListName}} wurde bereits geladen',
        title: 'Aktuelle Preisliste',
        topText: 'Partner Portal',
        dialogTitle: 'Preislisten laden',
        B2B: 'B2B',
        B2C: 'B2C',
        SERVICE_PRICE_LIST: 'Service',
        b2bPriceList: 'B2B Preisliste',
        b2cPriceList: 'B2C Preisliste',
        servicePriceList: 'Service Preisliste',
        sendPriceList: 'Preisliste senden',
        error: 'Fehler!',
        errorFetchTypeMessage: 'Fehler beim Abrufen der Preislistentypen',
        errorMessage: 'Das Formular konnte nicht gesendet werden. Bitte versuche es erneut.',
        errorFetchPriceList: 'Etwas ist schief gelaufen, wir konnten die Preislisten nicht abrufen',
        errorLackOfCustomerRmaID:
          'Der Benutzer muss über Customer RmaId verfügen,Customer RmaId im Admin-Bereich ausfüllen und erneut versuchen, die Datei hochzuladen',
        formSentSuccessfully: 'Das Formular wurde erfolgreich gesendet.',
        noPriceList: 'Keine Preislisten zum Herunterladen!',
        pdfDownload: 'PDF DOWNLOAD',
        priceListType: 'PREISLISTENTYP',
        fileName: 'DATEINAME',
      },
      profile: {
        topText: 'Partner Portal',
        title: 'Mein Profil',
        generalInformations: 'GRUNDLEGENDE INFORMATIONEN',
        account: 'ACCOUNT',
        fields: {
          company: 'Firma',
          contactPerson: 'Ansprechpartner',
          phoneNumber: 'Telefonnummer',
          emailAddress: 'E-Mail Adresse',
          website: 'Website',
          username: 'Benutzername',
        },
      },
      dictionaryRepairRequestStatus: {
        dataSent: {
          display: {
            value: 'Daten übermittelt',
            subvalue: 'Datenüberprüfung',
            description:
              'Nach erfolgreicher Überprüfung der Daten erhalten Sie weitere Informationen zum Versand.',
          },
          file: {
            title: 'Benutzerrechnung',
            subtitle: '',
            button: 'Rechnung herunterladen',
          },
        },
        requestTaken: {
          display: {
            value: 'Auftrag angenommen',
            subvalue: 'Versand vorbereiten',
            description: 'Bitte bereiten Sie den Versand vor und schicken Sie die Ware ab.',
          },
          file: {
            title: 'Ihr Versandetikett',
            subtitle:
              'Bitte drucken Sie Ihr Versandetikett aus und bringen Sie es auf der Verpackung an. Sobald Ihr Paket abgeholt wurde, können Sie es hier per Trackingnummer verfolgen.',
            button: 'Etikett drucken',
          },
        },
        parcelReceived: {
          value: 'Ware angekommen',
          subvalue: 'Qualitätsprüfung',
          description: '',
        },
        costEstimation: {
          display: {
            value: 'KVA freigeben',
            description: '',
          },
          file: {
            title: 'Kostenvoranschlag - Reparatur',
            subtitle:
              'Bitte sehen Sie sich den folgenden Kostenvoranschlag an. Wenn Sie einverstanden sind, drücken Sie bitte auf "KVA freigeben" und Ihre Reparatur wird veranlasst.',
            button: 'KVA FREIGEBEN',
          },
        },
        repairFinished: {
          value: 'Reparatur fertigstellen',
          subvalue: 'Qualitätsprüfung',
          description: '',
        },
        receipt: {
          display: { value: 'Rechnung', description: '' },
          file: {
            title: 'Rechnung - Reparatur',
            subtitle:
              'Bitte begleichen Sie die Rechnung. Nachdem der Geldeingang verbucht wurde, wird Ihre Ware versandt.',
            button: 'RECHNUNG HERUNTERLADEN',
            status: 'offen',
          },
        },
        parcelSentBack: { value: 'Ware versandt', description: '' },
        default: {
          file: {
            title: 'Dokument',
            subtitle: '',
            button: 'Dokument herunterladen',
          },
        },
      },
    },
  },
}

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    fallbackLng: 'de',
  })

export default i18n
