import { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import pdfIcon from '../../assets/icons/pdfIcon.svg'
import { fetchUserStartAction } from '../../requests/user/user.actions'
import { fetchFAQStartAction } from '../../requests/faq-request/faqRequest.action'
import { UserData } from '../../requests/user/user.domain'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import { RouteLink } from '../common'
import NewPageTitle from '../common/new-page-title/NewPageTitle'
import AccordionOption from '../common/page-menu/AccordionOption'
import LinkOption from '../common/page-menu/LinkOption'
import { fetchCustomerStartAction } from '../../requests/customer/customer.action'

export const RepairRequestMenu = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const userStore = useAppSelector((state) => state.user)
  const faqStore = useAppSelector((state) => state.faqList)
  const customerRmaStore = useAppSelector((state) => state.customerRmaList)
  const [userData, setUserData] = useState<UserData | null>(null)
  const faqData = useMemo(() => faqStore.faqData, [faqStore])
  const language = i18n.language

  useEffect(() => {
    if (userStore && userStore.userData) {
      setUserData(userStore.userData)
    } else {
      dispatch(fetchUserStartAction())
    }
  }, [userStore, dispatch])

  useEffect(() => {
    if (userData?.rmaCustomerId) {
      dispatch(fetchCustomerStartAction(userData.rmaCustomerId))
    }
  }, [userData, dispatch])

  useEffect(() => {
    dispatch(fetchFAQStartAction())
  }, [dispatch, language])

  const convertDateFormat = (date: string): string => {
    const [year, month, day] = date.split('-')
    if (year && month && day) {
      return `${day}.${month}.${year}`
    } else {
      return ''
    }
  }

  return (
    <>
      <div className="pt-8 pb-8">
        <NewPageTitle
          topText={t('repairRequestMenu.topText')}
          title={t('repairRequestMenu.title')}
          backButton
          navigateToMainPage
        >
          <div>{t('repairRequestMenu.pageDescriptionLine1')}</div>
          <div className="mt-4">{t('repairRequestMenu.pageDescriptionLine2')}</div>
        </NewPageTitle>
      </div>
      <div className="flex flex-col items-center pb-40">
        <LinkOption
          title={t('repairRequestMenu.linkOption')}
          url={RouteLink.REQUEST_SERVICE_START}
        />
        <AccordionOption title={t('repairRequestMenu.rmaTitle')} smallTopMargin smallBottomMargin>
          {customerRmaStore.rmaData.length > 0 ? (
            <div className="flex text-gray mb-5">
              <div className="w-full lg:w-4/12">
                <span>{t('repairRequestMenu.rma.product')}</span>
                <div>{t('repairRequestMenu.rma.serialNumber')}</div>
              </div>
              <div className="w-full lg:w-2/12">
                <span>{t('repairRequestMenu.rma.number')}</span>
                <div>{t('repairRequestMenu.rma.lastChange')}</div>
              </div>
              <div className="w-full lg:w-5/12">{t('repairRequestMenu.rma.status')}</div>
              <div className="w-full lg:w-1/12">{t('repairRequestMenu.rma.order')}</div>
            </div>
          ) : (
            <></>
          )}
          {customerRmaStore.rmaData.length > 0 &&
            customerRmaStore.rmaData.map((item, index) => (
              <div key={index}>
                <div className="flex items-center text-gray-15">
                  <div className="w-full lg:w-4/12">
                    {item.products.map((item) => (
                      <>
                        <div>{item.machine}</div>
                        <div className="mt-1">{item.serial}</div>
                      </>
                    ))}
                  </div>
                  <div className="w-full lg:w-2/12">
                    <span>{item.rma}</span>
                    <div>{convertDateFormat(item.lastStatusChangedate)}</div>
                  </div>
                  <div className="w-full lg:w-5/12">{item.status ? item.status : '-'}</div>
                  <div className="w-full lg:w-1/12">
                    <a
                      href={
                        item.pdfDocument?.includes('http')
                          ? item.pdfDocument
                          : `https://${item.pdfDocument}`
                      }
                      key={item.rma}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="w-8 h-8 ml-2">
                        <img src={pdfIcon} alt="pdf icon" />
                      </div>
                    </a>
                  </div>
                </div>
                <hr className="my-5 bg-gray-10" />
              </div>
            ))}
        </AccordionOption>
        <div className="w-full">
          <div className="flex flex-col items-center mt-20">
            <h3 className="font-avenir text-md text-gray tracking-widest uppercase mb-4">
              {t('faq.info')}
            </h3>
            <h1 className="font-sabon text-5xl text-gray-10 font-semibold tracking-wider mb-11">
              {t('faq.title')}
            </h1>
            {faqData.map((item, index) => (
              <AccordionOption title={item.question} key={index} marginBottom>
                <div className="flex items-center text-gray-15">
                  <div className="w-full flex justify-between items-center">
                    <div>
                      <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                    </div>
                  </div>
                </div>
              </AccordionOption>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
