import { call, put, takeLatest } from 'redux-saga/effects'

import {
  CUSTOMER_RMA_FETCH_START,
  ICustomerFetchStartAction,
  fetchUserActionInProgress,
  fetchUserActionDone,
  createCustomerActionError,
} from './customer.action'
import { fetchCustomerView } from './customer.service'
import { CustomerRmaType } from './customer.domain'

export function* watchFetchCustomerView() {
  yield takeLatest(CUSTOMER_RMA_FETCH_START, callCustomerView)
}

function* callCustomerView(action: ICustomerFetchStartAction) {
  try {
    yield put(fetchUserActionInProgress(action.userId))
    const response: CustomerRmaType[] = yield call(fetchCustomerView, action.userId)
    yield put(fetchUserActionDone(response))
  } catch (error: unknown) {
    yield put(createCustomerActionError(error as Error))
  }
}
